<script>
export const pageName = 'rockets-flight-set-outcome';
export const pageRoute = '/rockets/{rocketId}/flights/{flightId}/outcome';
export const title = 'Set Flight Outcome'
export const requireMembership = true;

import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { navigate } from '@trullock/page-manager'
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'
import DateTime from '../../../functions/lib/dateTime.js';
import { getProjection } from '../../js/lib/client-read-model.js';
import { getCurrentUserId } from '../../js/auth.js';
import UserView from '../../../functions/domain/users/userView.js';
import RocketView from '../../../functions/domain/rockets/rocketView.js';

export default {
	data() {
		return { 
			outcome: null,
			latitude: null,
			longitude: null,
			notes: null,

			ready: false
		}
	},

	methods: {
		async show(opts) {
			functions.warmUp.rocketFlightSetOutcome();

			this.rocket = await getProjection([UserView, getCurrentUserId(), RocketView, opts.rocketId])
			this.flight = this.rocket.getFlight(opts.flightId);
			
			this.outcome = this.flight.outcome;
			this.latitude = this.flight.landingLatitude;
			this.longitude = this.flight.landingLongitude;
			this.notes = this.flight.notes;
			
			this.ready = true;
		},
		geolocate() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async position => {
					this.latitude = position.coords.latitude;
					this.longitude = position.coords.longitude;
				});
			} else {
				showToast({
					message: 'Error geolocating you',
					style: 'bg-danger'
				})
			}
		},
		async submit () {
			showLoading();

			let flownOn = `${this.date} ${this.time}`;
			flownOn = new DateTime(flownOn)

			let result = await functions.rocketFlightSetOutcome({ 
				rocketId: this.rocket.id,
				flightId: this.flight.id,

				outcome: this.outcome,
				latitude: parseFloat(this.latitude || '0') || null,
				longitude: parseFloat(this.longitude || '0') || null,
				notes: this.notes
			});

			if(!result.success)
			{
				showToast({ message: 'Error setting flight outcome', style: 'bg-danger'})
				hideLoading();
				return;
			}


			navigate(`/rockets/${this.rocket.id}`)

			showToast({
				message: 'Flight outcome saved',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-xl-6 my-5">
				<h1 class="display-4 text-center mb-3">Set Flight Outcome</h1>
				<p class="text-muted text-center mb-5">How did it go?</p>
					
				<form @submit.prevent="submit">
					<div class="form-group">
						<h4 class="mb-3">
							Flight summary
						</h4>
						<div class="btn-group-toggle">
							<span>
								<input type="radio" class="btn-check" v-model="outcome" value="negative">
								<label class="btn btn-outline-danger">
									<i class="fe fe-x-circle"></i> Negative
								</label>
							</span>
							<span>
								<input type="radio" class="btn-check" v-model="outcome" value="neutral">
								<label class="btn btn-outline-secondary ml-2">
									<i class="fe fe-minus-circle"></i> Neutral
								</label>
							</span>
							<span>
								<input type="radio" class="btn-check" v-model="outcome" value="positive">
								<label class="btn btn-outline-success ml-2">
									<i class="fe fe-check-circle"></i> Positive
								</label>
							</span>
						</div>
						<span class="invalid-feedback"></span>
						<span class="form-text text-muted small">Was the flight a success?</span>
					</div>
					<hr class="mt-2 mb-4" />
					<h4 class="mb-3">Landing site</h4>
					<div class="row align-items-top">
						<div class="col">
							<div class="form-group mb-4">
								<label class="form-label">
									Latitude
								</label>
								<input type="number" class="form-control" v-model="latitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-4">
								<label class="form-label">
									Longitude
								</label>
								<input type="number" class="form-control" v-model="longitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-auto d-flex align-items-end pb-4 ps-0">
							<button @click.prevent="geolocate" class="btn btn-outline-secondary"><span class="fe fe-map-pin"></span></button>
						</div>
					</div>
					<hr class="mt-2 mb-4" />
					<div class="form-group">
						<label class="form-label">
							Notes
						</label>
						<textarea type="text" class="form-control" v-model="notes" rows="3" placeholder="e.g. Delay 1s too long"></textarea>
						<span class="invalid-feedback"></span>
					</div>
					<button class="btn w-100 btn-primary my-4">Save outcome <span class="fe fe-check-circle"></span></button>
				</form>
			</div>
		</div>
    </div>
</template>
