export default {
	subjects: {
		'training': {
			name: 'Training',
			color: 'purple',
			tasks: {
				'rocketry-principles': {
					name: 'Basic Rocketry Principles',
					xp: 2
				},
				'safety-principles' : {
					name: 'Basic Rocketry Safety',
					xp: 2
				}
			}
		},
		'propulsion': { 
			name: 'Propulsion',
			color: 'yellow',
			tasks: {
				'A/4': {
					name: '1/4 A',
					desc: 'Fly a rocket on a 1/4 A motor',
					xp: 1,
					rules: [
						'Rocket can be reused or new.'
					]
				},
				'A/2': {
					name: '1/2 A',
					desc: 'Fly a rocket on a 1/2 A motor',
					xp: 1,
					rules: [
						'Rocket can be reused or new.'
					]
				},
				'A': {
					name: 'A',
					desc: 'Fly a rocket on an A motor',
					xp: 1,
					rules: [
						'Rocket can be reused or new.'
					]
				},
				'B': {
					name: 'B',
					desc: 'Fly a rocket on a B motor',
					xp: 2,
					rules: [
						'Rocket can be reused or new.',
						'When clustering/staging it is an individual motor that counts, not total impulse of all motors'
					]
				},
				'C': {
					name: 'C',
					desc: 'Fly a rocket on a C motor',
					xp: 3,
					rules: [
						'Rocket can be reused or new.',
						'When clustering/staging it is an individual motor that counts, not total impulse of all motors'
					]
				},
				'D': {
					name: 'D',
					desc: 'Fly a rocket on a D motor',
					xp: 5,
					rules: [
						'Rocket can be reused or new.'
					]
				}
			}
		},
		'construction': {
			name: 'Construction',
			color: 'blue',
			tasks: {
				'rtf': {
					name: 'RTF',
					desc: 'Fly a Ready-To-Fly rocket',
					xp: 2,
					rules: [
						'Must be a rocket you havent used before in MAP.'
					]
				},
				'kit': {
					name: 'Kit',
					desc: 'Build and fly a rocket kit',
					xp: 5,
					rules: [
						'Must be built and flown by you.'
					]
				},
				'cone': {
					name: 'Chips away',
					desc: 'Build and fly a chip-cone',
					xp: 3,
					rules: [
						'Must be built and flown by you.'
					]
				},
				'whirly': {
					name: 'Whirly-bird',
					desc: 'Build and fly a spinning rocket',
					xp: 3,
					rules: [
						'Must be built and flown by you.'
					]
				},
				'scratch': {
					name: 'Scratch Build',
					desc: 'Design, build and fly a rocket from parts',
					xp: 10,
					rules: [
						'Must be designed, built and flown by you.'
					]
				},
				'2stagebp': {
					name: 'Two Stager (BP)',
					desc: 'Build and fly a 2-stage rocket on BP motors',
					xp: 10,
					rules: [
						'Must be built and flown by you.',
						'Both stages must be recovered'
					]
				},
				'clusterbp': {
					name: 'Cluster (BP)',
					desc: 'Build and fly a rocket with a cluster of BP motors',
					xp: 10,
					rules: [
						'Must be built and flown by you.',
						'All motors must ignite properly'
					]
				},
				'upscale': {
					name: 'Upscale',
					desc: 'Design, build and fly an upscaled rocket',
					xp: 10,
					rules: [
						'Must be scaled, built and flown by you.',
						'Must be an upscale of an existing kit or real rocket/missile'
					]
				},
				'downscale': {
					name: 'Downscale',
					desc: 'Design, build and fly a downscaled rocket',
					xp: 10,
					rules: [
						'Must be scaled, built and flown by you.',
						'Must be a downscale of an existing kit or real rocket/missile'
					]
				},
				'baffle': {
					name: 'Baffle',
					desc: 'Build and fly a an rocket with a baffle',
					xp: 5,
					rules: [
						'Must be built and flown by you.'
					]
				}
			}
		},
		'recovery': {
			name: 'Recovery',
			color: 'red',
			tasks: {
				'parachute': {
					name: 'Parachute',
					desc: 'Recover a rocket by parachute',
					xp: 2
				},
				'streamer': {
					name: 'Streamer',
					desc: 'Recover a rocket by streamer',
					xp: 1
				},
				'tumble': {
					name: 'Tumble',
					desc: 'Recover a rocket by tumbling',
					xp: 1
				},
				'glider': {
					name: 'Glider',
					desc: 'Recover using wings',
					xp: 10
				},
				'monocopter': {
					name: 'Monocopter',
					desc: 'Flutter down like a sycamore seed',
					xp: 10
				}
			}
		},
		'mission': {
			name: 'Mission',
			color: 'light',
			tasks: {
				'payload': {
					name: 'Non-fragile Payload',
					desc: 'Deploy a non-fragile payload at apogee',
					xp: 10
				},
				'altimeter': {
					name: 'Altimeter',
					desc: 'Fly an altimeter and log apogee',
					xp: 10
				}
			}
		},
		'launch': {
			name: 'Launch Control',
			color: 'green',
			tasks: {
				'piston': {
					name: 'Piston Launcher',
					desc: 'Launch with a piston launcher',
					xp: 10
				}
			}
		}
	},
	levels: [
		{
			path: 'recruit',
			name: 'Recruit',
			xpRequired: 4,
			tasks: [
				'training.rocketry-principles',
				'training.safety-principles'
			]
		},
		{
			path: 'cadet',
			name: 'Cadet',
			xpRequired: 10,
			tasks: [
				'propulsion.A/4',
				'propulsion.A/2',
				'propulsion.A',
				'construction.rtf',
				'construction.kit',
				'construction.cone',
				'construction.whirly',
				'recovery.parachute',
				'recovery.streamer',
				'recovery.tumble'
			]
		},
		{
			path: 'pilot',
			name: 'Pilot',
			xpRequired: 25,
			tasks: [
				'propulsion.B',
				'propulsion.C',
				'construction.scratch',
				'construction.2stagebp',
				'construction.clusterbp',
			]
		},
		{
			path: 'flight-engineer',
			name: 'Flight Engineer',
			xpRequired: 40,
			tasks: [
				'propulsion.D',
				'construction.scratch',
				'construction.upscale',
				'construction.downscale',
				'construction.baffle',
				'recovery.glider',
				'recovery.monocopter',
				'mission.payload',
				'mission.altimeter',
				'launch.piston'
			]
		},
		{
			path: 'mission-specialist',
			name: 'Mission Specialist',
			xpRequired: 80,
			tasks: [
			]
		}
	]
}