<script>
export const pageName = 'rockets-view';
export const pageRoute = '/rockets/{id}';
export const title = 'View Rocket'
export const requireMembership = true;

import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { navigate } from '@trullock/page-manager'
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'
import RocketView from '../../../functions/domain/rockets/rocketView.js';
import UserView from '../../../functions/domain/users/userView.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { getCurrentUserId } from '../../js/auth.js';
import { showConfirm } from '../../js/utils/confirm.js';

export default {
	data() {
		return { 
			rocket: null,
			flights: [],
			ready: false
		}
	},

	methods: {
		async boot(opts) {
			this.unsub = await listenToProjection([UserView, getCurrentUserId(), RocketView, opts.id], rocket => {
				this.rocket = rocket
				this.flights = rocket.flights;
				this.flights.sort((a, b) => b.flownOn - a.flownOn);
			});
			this.ready = true;
		},
		deleteRocket() {
			functions.warmUp.rocketDelete()

			showConfirm({
				title: 'Confirm deletion',
				message: `Are you sure you want to delete this rocket and all its associated flights?`,
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Delete rocket',
						style: 'danger',
						action: async () => {
							this.unsub();

							let result = await functions.rocketDelete({ rocketId: this.rocket.id })
							if(!result.success)
							{
								showToast({ message: 'Error deleting rocket', style: 'bg-danger'})
								hideLoading();
								return;
							}

							navigate('/rockets')

							showToast({
								message: 'Rocket deleted',
								style: 'bg-success'
							})
						}
					}
				]
			})
		},
		deleteFlight(flight) {
			
			functions.warmUp.rocketFlightDelete()

			showConfirm({
				title: 'Confirm deletion',
				message: `Are you sure you want to delete this flight?`,
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Delete flight',
						style: 'danger',
						action: async () => {

							let result = await functions.rocketFlightDelete({ rocketId: this.rocket.id, flightId: flight.id })
							if(!result.success)
							{
								showToast({ message: 'Error deleting flight', style: 'bg-danger'})
								hideLoading();
								return;
							}

							showToast({
								message: 'Flight deleted',
								style: 'bg-success'
							})
						}
					}
				]
			})
		},
		async cloneFlight(flight) {
			showLoading();

			await functions.rocketFlightClone({ rocketId: this.rocket.id, flightId: flight.id })
		
			showToast({
				message: 'Flight cloned',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-xl-6 my-5">
				<h1 class="display-4 text-center mb-3">{{ rocket.name }}</h1>
				<p class="text-muted text-center mb-5">Manage flights for this rocket.</p>
				
				<a :href="`/rockets/${this.rocket.id}/flights/add`" class="btn btn-primary d-block mb-4">
					Add new flight
					<span class="fe fe-plus-circle"></span>
				</a>
				<hr>
				<div v-for="flight in flights" :class="`card ${flight.outcome == 'negative' ? 'border-left-danger' : flight.outcome == 'neutral' ? 'border-left-secondary' : flight.outcome == 'positive' ? 'border-left-success' : null}`">
					<!-- <span class="badge js-cert" style="position: absolute; top:-5px; right: -5px;">
						<span class="fe fe-award"></span>
						<span class="js-level">Level 1</span>
					</span> -->
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col ms-n2">
								<h4 class="mb-1">
									<a :href="`/rockets/${rocket.id}/flights/${flight.id}`" class="">{{ flight.name || flight.motors }}</a>
								</h4>
								<p class="small text-muted mb-1">
									<time>{{ flight.flownOn.format('yyyy/MM/dd HH:mm') }}</time> @ <span>{{ flight.location || 'Unknown location'}}</span>
								</p>
								<p class="small text-muted mb-1" v-if="flight.motors && flight.name">
									{{ flight.motors }} {{ flight.delay !== null ? flight.delay + 's' : null }}
								</p>
							</div>
							<div v-if="!flight.outcome" class="col-auto">
								<a :href="`/rockets/${rocket.id}/flights/${flight.id}/outcome`" class="btn btn-sm btn-primary">
									Set outcome
								</a>
							</div>
							<div class="col-auto px-0">
								<div class="dropdown">
									<button class="btn p-0 dropdown-ellipses dropdown-toggle" aria-haspopup="true" aria-expanded="true">
										<i class="fe fe-more-vertical"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end">
										<a :href="`/rockets/${rocket.id}/flights/${flight.id}`" class="dropdown-item text-primary js-btn-flight-edit">
											<span class="fe fe-edit"></span> Edit
										</a>
										<a :href="`/rockets/${rocket.id}/flights/${flight.id}/outcome`" class="dropdown-item text-primary js-btn-flight-set-outcome">
											<span class="fe fe-send"></span> Set outcome
										</a>
										<button @click.prevent="deleteFlight(flight)" class="dropdown-item text-danger">
											<span class="fe fe-trash-2"></span> Delete
										</button>
									</div>
								</div>
							</div>
						</div>
						<p v-if="flight.notes" class="small mt-2">{{ flight.notes }}</p>
						<template v-if="flight.logs.length">
							<hr class="mb-0">
							<div class="list-group list-group-flush">
								<div v-for="(log, index) in flight.logs" :class="`list-group-item ${index == flight.logs.length - 1 ? 'pb-0' : ''}`">
									<div class="row align-items-center small">
										<div class="col ms-n2">
											{{ log.name }}
										</div>
										<div class="col-auto">
											<a :href="`/rockets/${rocket.id}/flights/${flight.id}/logs/${log.id}`" class="btn btn-sm btn-outline-primary" type="button"><span class="fe fe-eye"></span> View</a>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div v-if="rocket.flights.length == 0">
					<p class="text-muted text-center">
						There are currently no flights for this rocket.
					</p>
				</div>
			</div>
		</div>
    </div>
</template>
