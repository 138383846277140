<script>
export const pageName = 'rockets-add';
export const pageRoute = '/rockets/add';
export const title = 'Add Rocket'
export const requireMembership = true;

import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { navigate } from '@trullock/page-manager'
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'

export default {
	data() {
		return { 
			name : null,
			buildType: '',
			diameter: null,
			length: null,
			cp: null,
			mass: null,
			notes: null,
			isDirty: false,
			ready: false
		}
	},

	methods: {
		beforeHide() {
			return this.isDirty ? 'Are you sure you want to abandon this new rocket?' : null
		},
		async boot(opts) {
			functions.warmUp.rocketCreate();
			this.ready = true;
		},
		show(opts) {
			this.name = null
			this.buildType = ''
			this.diameter = null
			this.length = null
			this.cp = null
			this.mass = null
			this.notes = null
			this.isDirty = false;
		},
		changed() {
			this.isDirty = true;
		},
		async submit () {
			showLoading();

			let result = await functions.rocketCreate({ 
				name: this.name,
				buildType: this.buildType,
				diameter: parseInt(this.diameter || '0', 10) || null,
				length: parseInt(this.length || '0', 10) || null,
				cp: parseInt(this.cp || '0', 10) || null,
				mass: parseInt(this.mass || '0', 10) || null,
				notes: this.notes
			});

			if(!result.success)
			{
				showToast({ message: 'Error adding rocket', style: 'bg-danger'})
				hideLoading();
				return;
			}

			this.isDirty = false;

			navigate(`/rockets/${result.id}`)

			showToast({
				message: 'Rocket created',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-xl-6 my-5">
				<h1 class="display-4 text-center mb-3">Add Rocket</h1>
				<p class="text-muted text-center mb-5">Create a new rocket to begin adding flights to it.</p>
					
				<form @submit.prevent="submit" @change="changed">
					<div class="form-group">
						<label class="form-label">
							Name
						</label>
						<input type="text" class="form-control" v-model="name" name="rocket-name" placeholder="e.g. LOC Goblin" required autofocus>
						<span class="invalid-feedback mt-2 d-block"></span>
					</div>

					<div class="form-group">
						<label>Build Type</label>
						<select v-model="buildType" class="form-control" required>
							<option value="" disabled selected>Choose...</option>
							<option value="Kit">Kit</option>
							<option value="Modified Kit">Modified Kit</option>
							<option value="Scratch Build">Scratch Built</option>
							<option value="Other">Other/Unknown</option>
						</select>
						<span class="invalid-feedback"></span>
					</div>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<label>Diameter</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="diameter" class="form-control" placeholder="e.g. 100" />
									<div class="input-group-append">
										<div class="input-group-text">
											mm
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label>Length</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="length" class="form-control" placeholder="e.g. 100" />
									<div class="input-group-append">
										<div class="input-group-text">
											mm
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<label>Center of Pressure</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="cp" class="form-control" placeholder="e.g. 900" />
									<div class="input-group-append">
										<div class="input-group-text">
											mm
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
								<p class="form-text text-muted small mb-0">Measured from tip of nose.</p>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label>Mass without motors</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="mass" class="form-control" placeholder="e.g. 800" />
									<div class="input-group-append">
										<div class="input-group-text">
											g
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
					</div>
					
					<div class="form-group">
						<label class="form-label mb-1">Notes</label>
						<textarea class="form-control" v-model="notes" rows="4"></textarea>
					</div>

					<button class="btn w-100 btn-primary">Create rocket <span class="fe fe-check-circle"></span></button>
				</form>
			</div>
		</div>
    </div>
</template>
