<script>
export const pageName = 'map-2';
export const pageRoute = '/model-achievement-programme/v2';
export const title = 'MAP v2'

import map from '../../../functions/domain/map.js';

export default {
	data() {
		return {
			currentUser: null,
			data: null,
			ready: false
		}
	},

	methods: {
		async boot(opts) {

			let subjectsArray = Object.keys(map.subjects).map(k => ({
				id: k,
				name: map.subjects[k].name,
				color: map.subjects[k].color
			}));

			await Promise.all(subjectsArray.map(async s => {
				let icon = await import(`../../img/map/${s.color}.png`)
				s.icon = icon.default
			}));

			let taskIcons = await Promise.all(subjectsArray.flatMap(s => Object.keys(map.subjects[s.id].tasks)).map(async t => {
				
				let icon = await import(`../../img/map/${t.replaceAll('/', '_').toLowerCase()}.png`)
				return {
					id: t,
					path: icon.default
				}
			}));

			let data = {
				subjects: subjectsArray,
				levels: map.levels.map(l => ({
					name: l.name,
					xpRequired: l.xpRequired,
					subjectTasks: subjectsArray.map(subject => {

						let tasks = l.tasks.map(t => {
							let path = t.split('.')
							let subject = subjectsArray.find(s => s.id == path[0])
							let task = map.subjects[subject.id].tasks[path[1]]

							if(task == null)
							{
								throw new Error(`cant find ${t}`)
							}

							return {
								subject,
								taskId: path[1],
								task
							}
						});

						return {
							subject: subject,
							tasks: tasks.filter(t => t.subject == subject).map(t => {
							 	return {
									name: t.task.name,
									desc: t.task.desc,
									xp: t.task.xp,
									icon: taskIcons.find(i => i.id == t.taskId)
								}
							})
						}
					})
				}))
			}
			this.data = data;
			this.ready = true;
		},
		show() {
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5 text-center">
		<template v-for="(level, i) in data.levels">
			<h1 class="mb-2">{{ level.name }}</h1>
			<p class="text-muted mb-5">You need {{ level.xpRequired }} XP to graduate to the next rank.</p>
			<div class="row">
				<template v-for="subjectTask of data.levels[i].subjectTasks">
					<template v-if="subjectTask.tasks.length">
						<div v-for="task in subjectTask.tasks" class="col-3">
							<div class="card card-fill">
								<div class="card-body">
									<div class="d-inline-block" :style="`background: url(${subjectTask.subject.icon});`">
										<img :src="task.icon.path" />
									</div>
									<h2>{{ task.name }}</h2>
									<div class="text-muted">{{ task.desc }}</div>
									<span class="badge badge-white border border-1 border-light shadow font-weight-bold" style="position: absolute; top: -5px; right: -5px; font-size: 90%"><span class="text-primary">+</span> {{ task.xp }} XP</span>
								</div>
							</div>
						</div>
					</template>
				</template>
			</div>
			<hr class="my-5">
		</template>
    </div>
</template>
