<script>
export const pageName = 'rockets';
export const pageRoute = '/rockets';
export const title = 'Rockets'
export const requireMembership = true;

import RocketView from '../../../functions/domain/rockets/rocketView.js';
import { listenForProjections } from '../../js/lib/client-read-model.js'
import UserView from '../../../functions/domain/users/userView.js';
import { getCurrentUserId } from '../../js/auth.js'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'
import { showConfirm } from '../../js/utils/confirm.js';

export default {
	data() {
		return { 
			rockets: [],
			ready: false
		}
	},

	methods: {
		async boot(opts) {
			await listenForProjections([UserView, getCurrentUserId(), RocketView], this.rockets);
			this.ready = true;
		},
		
		deleteRocket(rocket) {
			functions.warmUp.rocketDelete()

			showConfirm({
				title: 'Confirm deletion',
				message: `Are you sure you want to delete this rocket and all its associated flights?`,
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Delete rocket',
						style: 'danger',
						action: async () => {

							let result = await functions.rocketDelete({ rocketId: rocket.id })
							if(!result.success)
							{
								showToast({ message: 'Error deleting rocket', style: 'bg-danger'})
								hideLoading();
								return;
							}

							showToast({
								message: 'Rocket deleted',
								style: 'bg-success'
							})
						}
					}
				]
			})
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-xl-6 my-5">
				<h1 class="display-4 text-center mb-3">Flight Logs</h1>
				<p class="text-muted text-center mb-5">Create rockets and log flights against them.</p>
					
				<a href="/rockets/add" class="btn btn-block btn-primary mb-5">Add new rocket <span class="fe fe-plus-circle"></span></a>

				<template v-if="rockets.length">
					<div class="card" v-for="rocket in rockets">
						<div class="card-body">
							<span class="small"></span>
							<div class="row align-items-center">
								<div class="col ms-n2">
									<h4 class="mb-1">
										<a :href="`/rockets/${rocket.id}`" class="">{{ rocket.name }}</a>
									</h4>
									<p class="small text-muted mb-0">
										Flights: {{ rocket.flights.length }}
									</p>
									<p class="small mt-2 mb-0" v-if="rocket.notes">{{ rocket.notes }}</p>
								</div>
								<div class="col-auto">
									<a :href="`/rockets/${rocket.id}`" class="btn btn-sm btn-outline-primary">
										View
										<span class="fe fe-arrow-right-circle"></span>
									</a>
								</div>
								<div class="col-auto px-0">
									<div class="dropdown">
										<button class="btn p-0 dropdown-ellipses dropdown-toggle" aria-haspopup="true" aria-expanded="true">
											<i class="fe fe-more-vertical"></i>
										</button>
										<div class="dropdown-menu dropdown-menu-end">
											<a :href="`/rockets/${rocket.id}/edit`" class="dropdown-item text-primary">
												<span class="fe fe-edit"></span> Edit
											</a>
											<a :href="`/rockets/${rocket.id}/flights/add`" class="dropdown-item text-primary">
												<span class="fe fe-send"></span> Add flight
											</a>
											<button @click.prevent="deleteRocket(rocket)" class="dropdown-item text-danger">
												<span class="fe fe-trash-2"></span> Delete
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<div class="text-center" v-else>
					<p class="text-muted">You don't currently have any rockets defined.</p>
				</div>
			</div>
		</div>
    </div>
</template>
