<script>
export const pageName = 'rockets-flight-add';
export const pageRoute = '/rockets/{rocketId}/flights/add';
export const title = 'Add Flight'
export const requireMembership = true;

import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { navigate } from '@trullock/page-manager'
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'
import DateTime from '../../../functions/lib/dateTime.js';
import { renderSize } from '../../js/utils/fileSize.js';

export default {
	data() {
		return { 
			date: null,
			dateMax: DateTime.now.format('yyyy-MM-dd'),
			time: null,
			name: null,
			location: null,
			latitude: null,
			longitude: null,
			motors: null,
			delay: null,
			mass: null,
			sm: null,
			notes: null,
			flightLogs: [],
			flightLogError: null,
			isDirty: false,
			ready: false
		}
	},

	methods: {
		beforeHide() {
			return this.isDirty ? 'Are you sure you want to abandon this new flight?' : null
		},
		async boot(opts) {
			functions.warmUp.rocketFlightAdd();
			this.rocketId = opts.rocketId;
			this.ready = true;
		},
		show(opts) {
			this.date = DateTime.now.format('yyyy-MM-dd')
			this.time = DateTime.now.format('HH:mm')
			this.name = null;
			this.location = null
			this.latitude = null
			this.longitude = null
			this.motors = null
			this.delay = null
			this.mass = null
			this.sm = null
			this.notes = null
			this.flightLogs = [];
			this.isDirty = false;
		},
		geolocate() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async position => {
					this.latitude = position.coords.latitude;
					this.longitude = position.coords.longitude;
				});
			} else {
				showToast({
					message: 'Error geolocating you',
					style: 'bg-danger'
				})
			}
		},
		changed() {
			this.isDirty = true;
		},
		inputChanged(e) {
			var files = [...e.target.files];
			this.handleFiles(files);
		},
		handleFiles(files) {
			
			this.flightLogError = '';

			let filesProcessed = 0;

			var done = async (log, file) => {
				filesProcessed++;
				if(filesProcessed == files.length)
					hideLoading();

				this.$refs.fupFlightLogs.value = null;

				let result = await functions.rocketFlightLogValidate({ log })
				if(!result.valid)
				{	
					this.flightLogError += `Unsupported flight log '${file.name}'. If this is a valid flight log file please email it to membership@ukra.org.uk so we can investigate supporting it.`;
					return;
				}

				if(file.size > 5 * 1024 * 1024)
				{	
					this.flightLogError += `'${file.name}' is too large, maximum log size is 5MB.`;
					return;
				}

				this.flightLogs.push({ 
					log, 
					name: file.name,
					size: file.size
				});

				this.$refs.fupFlightLogs.value = null;
			};

			let error = (result, e) => {
				filesProcessed++;
				if(filesProcessed == files.length)
					hideLoading();

				console.error(e);
			}

			showLoading();

			files.forEach(f => {
				let reader = new FileReader();
				reader.onload = () => done(reader.result, f);
				reader.onerror = e => error(reader.result, e);
				reader.readAsText(f);
			});
		},
		removeLog(log) {
			this.flightLogs.splice(this.flightLogs.findIndex(l => l == log), 1);
		},
		dropped(e) {
			e.preventDefault();
			let files = [...e.dataTransfer.files];
			
			let dudFiles = files.filter(f => f.type != "text/csv");

			dudFiles.forEach(file => {
				this.$refs.fupFlightLogs.setCustomValidity(`Unsupported file type '${file.name}'.`);
				this.$refs.fupFlightLogs.reportValidity();
			})
			
			files = files.filter(f => f.type == "text/csv");

			if(files.length)
				this.handleFiles(files);
		},
		dragover(e) {
			e.preventDefault();
		},
		uploadClick(e) {
			this.$refs.fupFlightLogs.click();
		},
		renderSize,
		async submit () {
			showLoading();

			let flownOn = `${this.date} ${this.time}`;
			flownOn = new DateTime(flownOn)

			let result = await functions.rocketFlightAdd({ 
				rocketId: this.rocketId,
				flownOn,
				name: this.name,
				location: this.location,
				latitude: parseFloat(this.latitude || '0') || null,
				longitude: parseFloat(this.longitude || '0') || null,
				motors: this.motors,
				delay: parseFloat(this.delay || '0') || null,
				mass: parseInt(this.mass || '0', 10) || null,
				sm: parseInt(this.sm || '0', 10) || null,
				notes: this.notes
			});

			if(!result.success)
			{
				showToast({ message: 'Error adding flight', style: 'bg-danger'})
				hideLoading();
				return;
			}

			await Promise.all(this.flightLogs.map(log => functions.rocketFlightLogAttach({
				rocketId: this.rocketId,
				flightId: result.flightId,
				log: log.log,
				name: log.name	
			})))

			this.isDirty = false;

			navigate(`/rockets/${this.rocketId}`)

			showToast({
				message: 'Flight added',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-xl-6 my-5">
				<h1 class="display-4 text-center mb-3">Add Flight</h1>
				<p class="text-muted text-center mb-5">Record a new flight.</p>
					
				<form @submit.prevent="submit" @change="changed">
					<div class="form-group">
						<label class="form-label">
							Flight description
						</label>
						<input type="text" class="form-control" v-model="name" name="flight-desc" placeholder="e.g. First flight" autofocus>
						<span class="invalid-feedback mt-2 d-block"></span>
						<p class="form-text text-muted small mb-0">Optional. A unique description for this flight.</p>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Date</label>
								<input type="date" class="form-control pe-3" v-model="date" :max="dateMax">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Time</label>
								<input type="time" class="form-control pe-3" v-model="time">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<hr class="mt-2 mb-4" />
					<div class="form-group">
						<label class="form-label">Launch site name</label>
						<input type="text" class="form-control" v-model="location" placeholder="e.g. Cape Canaveral">
						<span class="invalid-feedback"></span>
					</div>
					<div class="row">
						<div class="col">
							<div class="form-group mb-4">
								<label class="form-label">Latitude</label>
								<input type="number" class="form-control" v-model="latitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-4">
								<label class="form-label">Longitude</label>
								<input type="number" class="form-control" v-model="longitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-auto d-flex align-items-end pb-4 ps-0">
							<button @click.prevent="geolocate" class="btn btn-outline-secondary"><span class="fe fe-map-pin"></span></button>
						</div>
					</div>
					<hr class="mt-2 mb-4"/>
					<div class="row">
						<div class="col-9">
							<div class="form-group">
								<label class="form-label">
									Motor(s)
								</label>
								<input type="text" class="form-control" v-model="motors" placeholder="e.g. G80SK">
								<span class="feedback mt-2 d-block"></span>
							</div>
						</div>
						<div class="col-3">
							<div class="form-group">
								<label class="form-label">
									Delay
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="240" step="1" autocomplete="off" v-model="delay" class="form-control" placeholder="e.g. 7" />
									<div class="input-group-append">
										<div class="input-group-text">
											s
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Lift-off mass
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="mass" class="form-control" placeholder="e.g. 1100" />
									<div class="input-group-append">
										<div class="input-group-text">
											g
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Stability margin
								</label>
								<input type="number" class="form-control" v-model="sm" min="0" max="100" step="0.1" placeholder="e.g. 1.1">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label mb-1">Notes</label>
						<textarea class="form-control" v-model="notes" rows="4"></textarea>
					</div>
					
					<div class="form-group">
					<label>Flight logs</label>
						<div class="form-control form-control-dropzone">
							<div ref="dzLogs" class="dropzone dropzone-multiple dz-clickable" @click="uploadClick" @drop="dropped" @dragover="dragover">
								<input type="file" @change="inputChanged" ref="fupFlightLogs" multiple accept="text/csv" />
								<div class="dz-default dz-message">
									<button class="dz-button" type="button"><span class="fe fe-plus"></span> Add flight log CSVs</button>
								</div>
							</div>
							<div v-if="flightLogError" class="text-danger small mt-2">{{ flightLogError }}</div>
							<div class="list-group list-group-flush pt-4 my-n3">
								<div v-for="log of flightLogs" class="list-group-item">
									<div class="row align-items-center">
										<div class="col ms-n2">
											<h4 class="fw-normal mb-1">
												{{ log.name }}
											</h4>
											<small class="text-body-secondary">
											{{ renderSize(log.size) }} CSV
											</small>
										</div>
										<div class="col-auto">
											<button class="text-danger" type="button" @click="removeLog(log)"><span class="fe fe-x"></span></button>
										</div>
									</div>
								</div>
								<template v-for="log of existingLogs">
									<div v-if="!log.remove" class="list-group-item">
										<div class="row align-items-center">
											<div class="col ms-n2">
												<h4 class="fw-normal mb-1">
													{{ log.name }}
												</h4>
												<small class="text-body-secondary">
												{{ renderSize(log.size) }} CSV
												</small>
											</div>
											<div class="col-auto">
												<button class="text-danger" type="button" @click="removeExistingLog(log)"><span class="fe fe-x"></span></button>
											</div>
										</div>
									</div>
								</template>
							</div>
						</div>
						<p class="form-text text-muted small mb-0">This feature is still under development, if your flight log isnt supported please <a href="mailto:membership@ukra.org.uk">send it to us</a>.</p>
					</div>
					<button class="btn w-100 btn-primary my-4">Add flight <span class="fe fe-check-circle"></span></button>
				</form>
			</div>
		</div>
    </div>
</template>
