import AggregateRoot from "../../lib/aggregateRoot.js";
import { v4 as newId } from 'uuid'

export default class Rocket extends AggregateRoot
{
	name = null

	static create(issuingUserId, ownerId, id, createdOn)
	{
		let rocket = new Rocket();
		rocket.#create(issuingUserId, ownerId, id, createdOn);
		return rocket;
	}

	#create(issuingUserId, ownerId, id, createdOn)
	{
		this.apply('rocketCreated', {
			occurredOn: createdOn,
			issuingUserId,
			aggregateRootId: id,
			// ownerId must be present on every event from History viewing and SAR
			ownerId
		});
	}

	onRocketCreated(event) 
	{
		this.id = event.aggregateRootId;
		this.ownerId = event.ownerId;
		this.createdOn = event.occurredOn;
	}

	updateDetails(issuingUserId, name, buildType, diameter, length, cp, mass, notes)
	{
		this.apply('rocketDetailsUpdated', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			name,
			buildType,
			diameter,
			length,
			cp,
			mass,
			notes
		});
	}

	onRocketDetailsUpdated(event)
	{
		this.name = event.name;
		// no need to store anything else here
	}

	addFlight(issuingUserId, name, flownOn, location, latitude, longitude, motors, delay, mass, sm, notes)
	{
		let flightId = newId();

		this.apply('rocketFlightAdded', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			flightId: flightId,
			name,
			flownOn,
			location,
			latitude,
			longitude,
			motors,
			delay,
			mass,
			sm,
			notes
		});

		return flightId;
	}

	updateFlight(issuingUserId, flightId, name, flownOn, location, latitude, longitude, motors, delay, mass, sm, notes)
	{
		this.apply('rocketFlightUpdated', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			name,
			flightId,
			flownOn,
			location,
			latitude,
			longitude,
			motors,
			delay,
			mass,
			sm,
			notes
		});
	}

	setFlightOutcome(issuingUserId, flightId, outcome, latitude, longitude, notes)
	{
		this.apply('rocketFlightOutcomeSet', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			flightId,
			outcome,
			latitude,
			longitude,
			notes
		});
	}

	cloneFlight(issuingUserId, flightId)
	{
		let newFlightId = newId();

		this.apply('rocketFlightCloned', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			flightId,
			newFlightId
		});

		return newFlightId;
	}

	deleteFlight(issuingUserId, flightId)
	{
		this.apply('rocketFlightDeleted', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			flightId
		});
	}

	attachFlightLog(issuingUserId, flightId, logId, logName, meta)
	{
		this.apply('rocketFlightLogAttached', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			flightId,
			logId,
			logName,
			meta
		})
	}

	removeFlightLog(issuingUserId, flightId, logId)
	{
		this.apply('rocketFlightLogRemoved', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId,
			flightId,
			logId
		})
	}

	delete(issuingUserId)
	{
		this.apply('rocketDeleted', {
			issuingUserId,
			// ownerId must be present on every event from History viewing and SAR
			ownerId: this.ownerId
		})
	}
}